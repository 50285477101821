var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("head-layout", {
        attrs: {
          "head-btn-options": _vm.headBtnOptions,
          "head-title": "安全生产月报报表",
        },
        on: {
          "head-save": function ($event) {
            return _vm.headSave()
          },
        },
      }),
      _c(
        "el-scrollbar",
        [
          _c(
            "el-main",
            [
              _c(
                "el-form",
                {
                  ref: "formLayout",
                  staticClass: "demo-ruleForm form-container",
                  staticStyle: { "margin-top": "20px", width: "99%" },
                  attrs: {
                    model: _vm.dataForm,
                    rules: _vm.rules,
                    "label-width": "200px",
                  },
                },
                [
                  _c(
                    "el-row",
                    { staticClass: "custom-row", attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "企业名称", prop: "companyName" },
                            },
                            [
                              _c("template", { slot: "label" }, [
                                _c("span", [_vm._v("企业名称")]),
                              ]),
                              _c("el-input", {
                                attrs: {
                                  disabled: true,
                                  "controls-position": "right",
                                },
                                model: {
                                  value: _vm.dataForm.companyName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "companyName", $$v)
                                  },
                                  expression: "dataForm.companyName",
                                },
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "统计月份", prop: "dateAndTime" },
                            },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  type: "month",
                                  format: "yyyy-MM",
                                  "value-format": "yyyy-MM",
                                  disabled: true,
                                },
                                model: {
                                  value: _vm.dataForm.dateAndTime,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "dateAndTime", $$v)
                                  },
                                  expression: "dataForm.dateAndTime",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { staticClass: "custom-row", attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "填报日期", prop: "updateTime" },
                            },
                            [
                              _c("template", { slot: "label" }, [
                                _c("span", [_vm._v("填报日期:")]),
                              ]),
                              _c("el-date-picker", {
                                attrs: {
                                  format: "yyyy-MM-dd",
                                  "value-format": "yyyy-MM-dd",
                                  type: "date",
                                  disabled: "true",
                                  placeholder: "保存后自动生成",
                                },
                                model: {
                                  value: _vm.dataForm.updateTime,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "updateTime", $$v)
                                  },
                                  expression: "dataForm.updateTime",
                                },
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "安全工时",
                                prop: "safeWorkHours",
                              },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "calc(100% - 30px)" },
                                attrs: {
                                  "controls-position": "right",
                                  maxlength: "8",
                                  "show-word-limit": "",
                                  readonly: _vm.disabled,
                                },
                                model: {
                                  value: _vm.dataForm.safeWorkHours,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "safeWorkHours", $$v)
                                  },
                                  expression: "dataForm.safeWorkHours",
                                },
                              }),
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    effect: "dark",
                                    content: _vm.dataForm.safeWorkHoursS,
                                    placement: "bottom",
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-warning-outline iconLine",
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "本月安全生产投入（万元）",
                                prop: "safeInvest",
                              },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "calc(100% - 30px)" },
                                attrs: {
                                  "controls-position": "right",
                                  maxlength: "8",
                                  "show-word-limit": "",
                                  readonly: _vm.disabled,
                                },
                                model: {
                                  value: _vm.dataForm.safeInvest,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "safeInvest", $$v)
                                  },
                                  expression: "dataForm.safeInvest",
                                },
                              }),
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    effect: "dark",
                                    content: _vm.dataForm.safeInvestS,
                                    placement: "bottom",
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-warning-outline iconLine",
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "本月组织开展安全培训次数",
                                prop: "trainTimes",
                              },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "calc(100% - 30px)" },
                                attrs: {
                                  "controls-position": "right",
                                  maxlength: "8",
                                  "show-word-limit": "",
                                  readonly: _vm.disabled,
                                },
                                model: {
                                  value: _vm.dataForm.trainTimes,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "trainTimes", $$v)
                                  },
                                  expression: "dataForm.trainTimes",
                                },
                              }),
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    effect: "dark",
                                    content: _vm.dataForm.traintimesS,
                                    placement: "bottom",
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-warning-outline iconLine",
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "本月参加安全培训人数",
                                prop: "trainPeopleCnt",
                              },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "calc(100% - 30px)" },
                                attrs: {
                                  "controls-position": "right",
                                  maxlength: "8",
                                  "show-word-limit": "",
                                  readonly: _vm.disabled,
                                },
                                model: {
                                  value: _vm.dataForm.trainPeopleCnt,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.dataForm,
                                      "trainPeopleCnt",
                                      $$v
                                    )
                                  },
                                  expression: "dataForm.trainPeopleCnt",
                                },
                              }),
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    effect: "dark",
                                    content: _vm.dataForm.trainPeopleCntS,
                                    placement: "bottom",
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-warning-outline iconLine",
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "本月检查数", prop: "checkCnt" },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "calc(100% - 30px)" },
                                attrs: {
                                  "controls-position": "right",
                                  maxlength: "8",
                                  "show-word-limit": "",
                                  readonly: _vm.disabled,
                                },
                                model: {
                                  value: _vm.dataForm.checkCnt,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "checkCnt", $$v)
                                  },
                                  expression: "dataForm.checkCnt",
                                },
                              }),
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    effect: "dark",
                                    content: _vm.dataForm.checkCntS,
                                    placement: "bottom",
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-warning-outline iconLine",
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "本月排查出安全隐患数量",
                                prop: "hdCnt",
                              },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "calc(100% - 30px)" },
                                attrs: {
                                  "controls-position": "right",
                                  maxlength: "8",
                                  "show-word-limit": "",
                                  readonly: _vm.disabled,
                                },
                                model: {
                                  value: _vm.dataForm.hdCnt,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "hdCnt", $$v)
                                  },
                                  expression: "dataForm.hdCnt",
                                },
                              }),
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    effect: "dark",
                                    content: _vm.dataForm.hdCntS,
                                    placement: "bottom",
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-warning-outline iconLine",
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "其中排查出重大隐患数量",
                                prop: "majorHDCnt",
                              },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "calc(100% - 30px)" },
                                attrs: {
                                  "controls-position": "right",
                                  maxlength: "8",
                                  "show-word-limit": "",
                                  readonly: _vm.disabled,
                                },
                                model: {
                                  value: _vm.dataForm.majorHDCnt,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "majorHDCnt", $$v)
                                  },
                                  expression: "dataForm.majorHDCnt",
                                },
                              }),
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    effect: "dark",
                                    content: _vm.dataForm.majorHDCntS,
                                    placement: "bottom",
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-warning-outline iconLine",
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "本月整改完成数量",
                                prop: "rectifiedHdCnt",
                              },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "calc(100% - 30px)" },
                                attrs: {
                                  "controls-position": "right",
                                  maxlength: "8",
                                  "show-word-limit": "",
                                  readonly: _vm.disabled,
                                },
                                model: {
                                  value: _vm.dataForm.rectifiedHdCnt,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.dataForm,
                                      "rectifiedHdCnt",
                                      $$v
                                    )
                                  },
                                  expression: "dataForm.rectifiedHdCnt",
                                },
                              }),
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    effect: "dark",
                                    content: _vm.dataForm.rectifiedHdCntS,
                                    placement: "bottom",
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-warning-outline iconLine",
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "本月应急演练次数",
                                prop: "emergencDrillCnt",
                              },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "calc(100% - 30px)" },
                                attrs: {
                                  "controls-position": "right",
                                  maxlength: "8",
                                  "show-word-limit": "",
                                  readonly: _vm.disabled,
                                },
                                model: {
                                  value: _vm.dataForm.emergencDrillCnt,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.dataForm,
                                      "emergencDrillCnt",
                                      $$v
                                    )
                                  },
                                  expression: "dataForm.emergencDrillCnt",
                                },
                              }),
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    effect: "dark",
                                    content: _vm.dataForm.emergencyDrillCntS,
                                    placement: "bottom",
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-warning-outline iconLine",
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }